import React from 'react';
import {CssBaseline, ThemeProvider} from '@mui/material';
import {AppCacheProvider} from '@mui/material-nextjs/v14-pagesRouter';

import {AppMui} from '@/styles/mui';
import {MyAppProps} from '@/model/props/my-app.props';

import '../styles/main.scss';

const App = (props: MyAppProps): React.JSX.Element => {
  const {Component, pageProps} = props;

  return (
    <AppCacheProvider {...props}>
      <ThemeProvider theme={AppMui.theme()}>
        <CssBaseline/>
        <Component {...pageProps}/>
      </ThemeProvider>
    </AppCacheProvider>
  );
};

export default App;
