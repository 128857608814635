import {red} from '@mui/material/colors';
import {createTheme, PaletteMode, Theme} from '@mui/material';

export class AppMui {

  private constructor() {
  }

  public static theme(): Theme {
    const mode: PaletteMode = 'dark';

    return createTheme({
      palette: {
        mode,
        primary: {
          main: '#1F1F1F',
          contrastText: '#FFF'
        },
        secondary: {
          main: '#FFC107',
          contrastText: '#1F1F1F'
        },
        error: {
          main: red.A200,
        },
        background: {
          default: '#1F1F1F'
        }
      },
      typography: {
        fontFamily: ['"Exo 2"', 'sans-serif'].join(',')
      }
    });
  }

}
